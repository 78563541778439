<template>
    <b-modal id="questions-modal" @ok="handleSubmitQuestion" ref="modal" title="Some quicks question before you go" ok-only>
        <div class="question-content">
            <div class="question">
                <h5 class="question-title">Are you interested in purchasing tickets for the {{awardName}}
              Ceremony and receive further details?</h5>
                <div>
                    <input class="question-choice" type="radio" id="yes" value="Y" v-model="purchase_tickets" />
                    <label for="yes">Yes</label>
                </div>
                <div>
                    <input class="question-choice" type="radio" id="no" value="N" v-model="purchase_tickets" />
                    <label for="no">No</label>
                </div>
                <div>
                    <input class="question-choice" type="radio" id="ns" value="NS" v-model="purchase_tickets" />
                    <label for="ns">Not sure yet.</label>
                </div>
            </div>
            <div style="height: 4vh; width: 100%; border-top: 1px solid #eee"></div>
           <div class="question">
                <h5 class="question-title">Are you interested in the {{awardName}} Sponsorship?</h5>
                <div>
                    <input class="question-choice" type="radio" id="y" value="Y" v-model="become_sponser" />
                    <label for="y">Yes</label>
                </div>
                <div>
                    <input class="question-choice" type="radio" id="n" value="N" v-model="become_sponser" />
                    <label for="n">No</label>
                </div>
                <div>
                    <input class="question-choice" type="radio" id="not_sure" value="NS" v-model="become_sponser" />
                    <label for="not_sure">Not sure yet.</label>
                </div>
           </div>
        </div>
        <div style="height: 7vh; width: 100%; border-top: 1px solid #eee"></div>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'QuestionModal',
    props: {
    awardName: {
      type: [String],
      default: '',
    },
    companyUrl: {
      type: [String],
      default: '',
    },
    customerId : {
      type: [Number],
      default: 0,
    },
    awardUrl: {
      type: [String],
      default: '',
    },
    awardId: {
      type: [Number, String],
      default: 0,
    },
  },
    data() {
        return {
            purchase_tickets: 'Y',
            become_sponser: 'Y',
        }
    },
    methods: {
        ...mapActions({
            submitQuestionsAnswers: 'awards/submitQuestionsAnswers',
        }),
        async handleSubmitQuestion(e) {
            e.preventDefault();
            try {
                await this.submitQuestionsAnswers({
                awardId: this.awardId,
                customerId: this.customerId,
                answers: {
                    company: this.companyUrl,
                    award: this.awardUrl,
                    purchase_tickets: this.purchase_tickets,
                    become_sponser: this.become_sponser,
                },
            });
            } catch (err) {
                console.log(err);
            }

            this.$emit('done');
        }
    }
}
</script>

<style>
.question-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.question-choice {
    margin-right: 10px;
}
label, input {
    cursor: pointer;
}
.question {
    margin-bottom: 20px;
}
.question-title {
    margin-bottom: 20px;
}
.modal-header .close {
    display: none !important;
}
</style>